'use client';
import { Fragment, useTransition } from 'react';
import { useCart } from '../../_providers/Cart';
import { useLocale, useTranslations } from 'next-intl';
import { formatPrice } from '../../_utilities/format-price';
import { Button } from '../../_components/Button';
import { useRouter } from '@/navigation';
import { Skeleton } from '@shadcn/components/ui/skeleton';
import { useAuth } from '../../_providers/Auth';
import dayjs from 'dayjs';
import { DiscountCodeForm } from '../discount-code-form';
import { Flatten } from '../../_utilities/typescript';
import { Courseversion, OrderCartItems } from '@/payload/payload-types';
import { CartItem } from '../../_providers/Cart/reducer';
import { useTrackEvent } from '../../_providers/TrackEventProvider';

export const CartTotal: React.FC = () => {
	const t = useTranslations('Cart');
	const { cartTotal, cartTotalVat, cartHasVat } = useCart();

	return (
		<div>
			<div className="flex items-center justify-between space-x-8">
				<p className="text-gold text-sm font-bold tracking-widest uppercase">
					{t('total')}
				</p>
				<hr className="bg-gold-light h-[1px] w-max grow border-0 opacity-20" />

				<p className="text-muted-navy text-xl font-bold">{cartTotal.formatted}</p>
			</div>

			<p className="mt-0 text-sm leading-6 font-medium">
				{cartHasVat
					? t('purchase-includes-vat', { totalVat: cartTotalVat.formatted })
					: t('purchase-does-not-include-vat')}
			</p>
		</div>
	);
};

const DiscountedRow: React.FC<{
	courseversion: Courseversion;
	cartItem: CartItem;
	type: 'discount' | 'instalmentDiscount';
}> = ({ courseversion, cartItem, type }) => {
	const locale = useLocale();
	const t = useTranslations('Cart');
	const { instalmentSinglePaymentPriceReductionPercentage } = useCart();
	const hasCourseversionDiscount =
		courseversion.discountStatus === 'enabled' && Boolean(courseversion.discountName);
	const hasInstalmentPaymentDiscount = Boolean(
		cartItem.isInstalmentPaymentsPriceReductionApplied,
	);

	if (type === 'discount') {
		if (!hasCourseversionDiscount) {
			return null;
		}

		if (!cartItem.price || !cartItem.discountedPrice || !cartItem.discountedPriceReduction) {
			return null;
		}
	} else {
		if (!hasInstalmentPaymentDiscount) {
			return null;
		}

		if (!cartItem.discountedInstalmentPriceReduction) {
			return null;
		}
	}

	return (
		<Fragment>
			{type === 'discount' && (
				<Fragment>
					<label className="text-success text-sm italic">
						{courseversion.discountName}
					</label>
					<p className="text-success font-bold">
						-{formatPrice(cartItem.discountedPriceReduction!, locale, 'EUR')}
					</p>
				</Fragment>
			)}

			{type === 'instalmentDiscount' && (
				<Fragment>
					<label className="text-success text-sm italic">
						{t('instalment-price-reduction-applies', {
							percentageNumber: instalmentSinglePaymentPriceReductionPercentage,
						})}
					</label>
					<p className="text-success font-bold">
						-{formatPrice(cartItem.discountedInstalmentPriceReduction!, locale, 'EUR')}
					</p>
				</Fragment>
			)}
		</Fragment>
	);
};

export const CartItemPriceRow: React.FC<{
	cartItem: NonNullable<Flatten<OrderCartItems>>;
	courseversion: Courseversion;
	showQuantity?: boolean;
}> = ({ cartItem, courseversion, showQuantity }) => {
	const t = useTranslations('Cart');
	const locale = useLocale();
	const { isCartSyncInProgress, cart } = useCart();

	const title =
		typeof courseversion.course !== 'string' ? courseversion.course.title : courseversion.title;

	return (
		<div className="flex flex-col">
			<div className="flex justify-between space-x-4">
				<p>{title}</p>

				<p className="font-bold">
					{formatPrice(cartItem.price! * cartItem.quantity, locale, 'EUR')}
				</p>
			</div>

			{showQuantity ? (
				<label className="text-gold-light text-sm italic">
					{dayjs(courseversion.timeEdit.startDate).format('DD.MM')} -{' '}
					{dayjs(courseversion.timeEdit.endDate).format('DD.MM')}
					{' | '}
					{t('slots', {
						slots: cartItem.quantity,
					})}
				</label>
			) : null}

			<div className="flex items-baseline justify-between space-x-4">
				<DiscountedRow courseversion={courseversion} cartItem={cartItem} type="discount" />
			</div>
			<div className="flex items-baseline justify-between space-x-4">
				<DiscountedRow
					courseversion={courseversion}
					cartItem={cartItem}
					type="instalmentDiscount"
				/>
			</div>
		</div>
	);
};

export const CartSummary: React.FC<{
	confirmToCheckout?: boolean;
	disclaimerText?: string;
	showQuantity?: boolean;
	showDiscountCodeInput?: boolean;
}> = props => {
	const { confirmToCheckout, disclaimerText, showQuantity, showDiscountCodeInput } = props;
	const t = useTranslations('Cart');
	const { user } = useAuth();
	const locale = useLocale();
	const router = useRouter();
	const [isPending, startTransition] = useTransition();
	const { cart, hasInitializedCart, isCartSyncInProgress } = useCart();
	const trackEvent = useTrackEvent();

	return (
		<Fragment>
			<h1 className="text-muted-navy mb-6 text-4xl font-light lg:mb-10 lg:text-5xl">
				{t('summary')}
			</h1>
			<div className="bg-gold-transparent rounded-3xl p-10">
				{!hasInitializedCart ? (
					<Skeleton className="flex h-[220px] w-full bg-transparent"></Skeleton>
				) : !cart?.cartItems?.length && hasInitializedCart ? (
					<p>{t('cartEmpty')}</p>
				) : (
					<Fragment>
						<div className="mb-10 space-y-5">
							{hasInitializedCart &&
								cart.cartItems?.map(cartItem => {
									if (
										!cartItem.orderedCollection ||
										typeof cartItem.orderedCollection.value === 'string' ||
										!cartItem.quantity
									) {
										return null;
									}

									const item = cartItem.orderedCollection.value;

									return (
										<Fragment key={`cartSummaryComponent-${item.id}`}>
											<CartItemPriceRow
												showQuantity={showQuantity}
												cartItem={cartItem}
												courseversion={item}
											/>
										</Fragment>
									);
								})}
						</div>

						{hasInitializedCart && showDiscountCodeInput && (
							<div>
								<DiscountCodeForm />
							</div>
						)}

						<CartTotal />

						{confirmToCheckout && (
							<Button
								className="mt-10 w-full"
								intent="secondary"
								onClick={() => {
									startTransition(() => {
										router.push('/checkout');
										trackEvent('checkout_clicked', {});
									});
								}}
							>
								{t('checkout')}
							</Button>
						)}
					</Fragment>
				)}
			</div>
		</Fragment>
	);
};
